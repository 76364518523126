<template>
  <div class="container">
    <div class="title">企业评价</div>
    <div class="line"></div>
    <div class="writeEvaluate">
      <el-input
          class="input"
          type="textarea"
          :rows="2"
          :autosize="{ minRows: 2, maxRows: 7 }"
          placeholder="写下您对该企业的留言"
          v-model="textarea"
          maxlength="200"
          show-word-limit
      ></el-input>
      <div class="publishedBtn">
        <el-button
            class="addBtn"
            type="primary"
            @click="addComment"
            :loading="addCommentBtnLoading"
            :disabled="setBooth"
        >发  表
        </el-button>
      </div>
    </div>
    <div class="evaluateData" v-loading="loading">
      <div class="total" v-if="evaluationData.length">
        共{{ total }}条评论
      </div>
      <div class="evaluateData-wrap"
           v-for="(item, index) in evaluationData"
           :key="index"
      >
        <div class="item">
          <div class="top">
            <div class="image-wrap">
              <el-image
                  class="img"
                  :src="item.avatar?item.avatar:defaultAvatar"
                  fit="cover"
              ></el-image>
            </div>
            <div class="content">
              <div class="name">
                {{ item.user_name_en|priorFormat(item.user_name_zh,LOCALE) }}
              </div>
              <div class="companyNmae">
                <span class="position">
                  {{ item.user_job_title_en|priorFormat(item.user_job_title_zh,LOCALE) }}
                </span>
                <span class="fullName">
                  {{ item.company_name_en|priorFormat(item.company_name_zh,LOCALE) }}
                </span>
              </div>
            </div>
            <div class="time">
              <span>
                {{ item.comment_created_time|secondFormat }}
              </span>
              <span class="icon" v-if="item.user_id == USER_ID">
                <i style="cursor: pointer"  @click="isShowDeleteBtn(index)" class="el-icon-more"></i>
              </span>
            </div>
            <div class="delete" :class="{ deleteIsShow: index == isShowDelBtn }">
              <div
                  class="deletebtn"
                  @click="deleteThisComment(item)"
                  style="cursor: pointer"
              >删除
              </div>
            </div>
          </div>
          <div class="detail textFollow">
            {{ item.content|textFormat }}
          </div>
        </div>
      </div>
      <noDataImg v-if="evaluationData.length === 0" />
    </div>
    <div class="footer-content" v-if="total>5">
      <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="5"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    name: "companyEvaluate",
    props: {
      company_id: {
        type: Number | String,
        default: 0
      },
      setBooth:{
        type: Boolean,
        default:false
      }
    },
    data() {
      return {
        textarea: "",
        loading: false,
        addCommentBtnLoading: false,
        evaluationData: [],
        total: 0,
        currentPage: 1,
        isShowDelBtn: -1,
        defaultAvatar: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/grounp.png'
      }
    },
    watch: {
      currentPage() {
        this.getList()
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      //获取评价列表
      async getList() {
        this.loading = true
        let params = {}
        params.commented_company_id = this.company_id
        params.start = this.currentPage - 1
        params.limit = 5
        params.nopage = 0
        try {
          let res = await this.$store.dispatch("API_company/getCommentList", params)
          if (res.success) {
            this.evaluationData = res.data
            this.total = res.count
            this.loading = false
          }
        } catch (e) {

        }
      },
      // 添加评论
      addComment() {
        if (this.textarea.trim().length === 0){
          this.$message.warning("评价内容不能为空");
          this.textarea=""
          return
        }
        if (!this.IS_LOGIN){
          this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN")
          return
        }
        this.whritEvelick();
      },
      async whritEvelick(){
        this.addCommentBtnLoading=true
        let params={}
        params.commented_company_id=this.company_id
        params.source=5,
        params.content=this.textarea
        params.user_id=this.$store.state.baseStore.userInfo.id
        params.audit_status=0
        params.company_id = this.USER_INFO.company_id
        try {
          let res=await this.$store.dispatch('API_company/addComment',params)
          if (res.success){
            this.textarea=''
            this.currentPage=1
            this.addCommentBtnLoading=false
            this.getList()
          }
        }catch (e) {

        }
      },
      //点击原点显示删除按钮
      isShowDeleteBtn(index) {
        if (this.isShowDelBtn == index) {
          this.isShowDelBtn = -1;
        } else {
          this.isShowDelBtn = index;
        }
      },
      async deleteThisComment(item) {
        let params = {};
        params.user_id = item.user_id;
        params.comment_id = item.comment_id;
        try {
          let result = await this.$store.dispatch(
            "API_company/deleteMyComment",
            params
          );
          if (result.success) {
            this.getList();
            this.isShowDelBtn = -1;
          }
        } catch (e) {}
      },
    }
  }
</script>

<style scoped lang="less">
  .container {
    margin-top: 20px;
    padding: 24px 24px 25px;
    background: #FFFFFF;
    margin-bottom: 20px;
    .title {
      color: #333333;
      font-size: 16px;
      font-weight: 800;
    }
    .line {
      height: 1px;
      background: #DCDFE6;
      width: 100%;
      margin-top: 20px;
    }
    .delete {
      position: absolute;
      left: 850px;
      top: 34px;
      border: 1px solid #d7d7d7;
      width: 80px !important;
      display: none;
      background: #fff;

      div {
        color: #333;
        height: 25px;
        line-height: 25px;
        text-align: center;
      }

      .report {
        width: 100%;
        border-top: 1px solid #d7d7d7;
      }
    }
    .deleteIsShow {
      display: block;
    }
    .textFollow{
      word-break: break-all;
      word-wrap: break-word;
      break-word: break-all;
    }
    .writeEvaluate {
      width: 100%;
      background: #F6F6F6;
      border-radius: 2px;
      padding: 16px;

      .publishedBtn {
        text-align: right;
        margin-top: 16px;
        .addBtn {
          background-color: #3384b1;
          border-color: #3384b1;
          padding: 8px 27px;
          font-size: 16px;
          border-radius: 4px;
          color: #FFFFFF;
        }
      }
    }
    .evaluateData {
      min-height:200px;
      position: relative;
      .total {
        margin-top: 20px;
        margin-bottom: 8px;
      }

      .evaluateData-wrap {
        .item {
          padding: 16px 0;
          border-bottom: 1px solid #E9E9E9;
        }

        .top {
          display: flex;
          position:relative;
          .image-wrap {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 16px;

            .img {
              width: 48px;
              height: 48px;
              border-radius: 50%;
            }
          }

          .content {
            padding-top: 5px;
            flex: 1;

            .name {
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }

            .companyNmae {
              margin-top: 4px;
              font-size: 12px;
              font-weight: 400;
              color: #999999;

              .position {
                margin-right: 5px;
              }
            }
          }

          .time {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            width: 160px;
            font-size: 12px;
            font-weight: 400;
            color: #939393;

          }
        }

        .detail {
          padding: 16px 0;
        }
      }
    }
    .footer-content {
      text-align: right;
      padding: 15px 0;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #FF5155;
      }
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #FF5155;
      }
    }
  }

</style>